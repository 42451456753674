<template>
    <v-autocomplete
        v-model="data.list"
        multiple
        chips
        :items="data.items"
        @click:append="clickAppend()"
        v-bind="reprops()"
        @update:modelValue="updateHandler()"
        @update:menu="updateMenu"
    >
        <template v-slot:chip="{ props, item, index }">
            <v-chip size="large" v-bind="props" @click="clickChip(index)"> <v-icon start :icon="getChipIcon(index)"></v-icon> {{ item.title }} </v-chip>
        </template>
        <template v-slot:prepend-item>
            <v-list-item>
                <v-btn-toggle borderless>
                    <v-btn @click="remove()" :disabled="!listValid()">
                        <span class="hidden-sm-and-down">##&en Remove ##&hu Eltávolítás ##</span>
                        <v-icon end> mdi-close-circle </v-icon>
                    </v-btn>
                    <v-btn @click="left()" :disabled="!listValid()">
                        <span class="hidden-sm-and-down">##&en Left ##&hu Balra ##</span>
                        <v-icon end> mdi-arrow-left-box </v-icon>
                    </v-btn>
                    <v-btn @click="right()" :disabled="!listValid()">
                        <span class="hidden-sm-and-down">##&hu Right ##&hu Jobbra ##</span>
                        <v-icon end> mdi-arrow-right-box </v-icon>
                    </v-btn>
                    <div>
                        <v-btn color="primary" @click="openFileUploadDialog">Upload Files</v-btn>
                        <input ref="fileInput" type="file" multiple accept="*/*" style="display: none" @change="handleFileUpload" />
                    </div>
                </v-btn-toggle>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:no-data></template>
    </v-autocomplete>
</template>
<script setup>
// sortable array of primitives

import { ref, reactive, watch, nextTick, toRaw } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}
function isArray(i) {
    return Object.prototype.toString.call(i) === "[object Array]";
}

const fileInput = ref(null);

const openFileUploadDialog = () => {
    fileInput.value.click();
};

const handleFileUpload = (event) => {
    const files = event.target.files;
    // Process the uploaded files here
    console.log(files);
};

const emit = defineEmits(["update:modelValue", "update:valid", "update", "input"]);
const props = defineProps(["modelValue", "is", "class", "label", "clearable", "hint", "mandatory", "items", "selecting", "readonly"]);

const selecting = props.selecting === true;
//const selecting = true;

const reprops = () => {
    const re = clone(props);
    delete re.items;
    delete re.modelValue;
    re.multiple = true;
    re.chips = true;
    return re;
};

// the default is undefined null or array
let init = {
    list: [],
    index: null,
    items: props.items || [],
};

if (!selecting) if (isArray(props.modelValue)) init.list = props.modelValue;

// in selecting mode,{ list, index }
if (selecting)
    if (isObject(props.modelValue)) {
        Ł(props.modelValue.list);
        if (isArray(props.modelValue.list)) init.list = props.modelValue.list;
        init.index = props.modelValue.index;
    }

const data = reactive(init);

const left = () => {
    if (data.index === null) return;
    if (data.index < 1) return;
    const i = data.index;
    const value = data.list[i];
    data.list[i] = data.list[i - 1];
    data.list[i - 1] = value;
    data.index--;
    updateHandler();
};
const right = () => {
    if (data.index === null) return;
    if (data.list.length - data.index < 2) return;
    const i = data.index;
    const value = data.list[i];
    data.list[i] = data.list[i + 1];
    data.list[i + 1] = value;
    data.index++;
    updateHandler();
};
const remove = () => {
    if (data.index === null) return;
    const i = data.index;
    if (i + 1 === data.list.length) data.index = data.index - 1;
    data.list.splice(i, 1);
    if (data.list.length < 1) data.index = null;
    updateHandler();
};

const listValid = () => {
    if (data.index === null) return false;
    if (data.index < data.list.length) return true;
    return false;
};

const getChipIcon = (i) => {
    if (data.index === i) return "mdi-circle";
    return "mdi-circle-outline";
};

const clickChip = (i) => {
    data.index = i;
    updateHandler();
};

const isValid = () => {
    if (props.mandatory === true) if (data.list.length < 1) return "##&en At least one element must be index ##&hu legalább egy elem választása kötelező ##";
    return true;
};

const updateHandler = async () => {
    await nextTick();
    const valid = isValid();
    let datum = null;

    if (valid === true)
        if (selecting) datum = clone({ list: data.list, index: data.index, selected: data.list[data.index] });
        else datum = clone(data.list);

    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", valid);
};
const updateMenu = (e) => {
    if (!selecting) data.index = null;
};
</script>
