<template>
    <div class="pa-16" v-if="data.format.database_collection?.items?.length > 0">
        <vuetifind-dialog :format="data.format" :values="selection" @update="update" @clickEnter="add" label="##&en Add new ##&hu Hozzáadása ##" />
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" />
    </div>
    <v-container v-else>
        <v-alert v-if="data.format.database_collection"
            density="compact"
            type="warning"
            title="##&en No collection for queries ##&hu Nincs lekérdezés kollekció ##"
            text="##&en In order to create queries, you  need to create a collection with the queries pattern. ##&hu Lekérdezések használatához létre kell hozni egy adatbázis kollekciót, ami a lekérdezések pattern alapján dolgozik ##"
        ></v-alert>
    </v-container>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const router = useRouter();

const selection = reactive({});

const data = reactive({
    structure: {},
    format: {
        /*database_collection: {
            items: [],
        },*/
    },
});

const leftClickElement = ({ database, collection, _id }) => {
    router.push("/queries/" + database + "/" + collection + "/" + _id);
};

const add = (e) => {
    $socket.emit("queries:QueriesSelection:add", { selection }, ({ database, collection, _id }) => {
        if (_id) router.push("/queries/" + database + "/" + collection + "/" + _id);
    });
};

const update = (a) => {
    Object.assign(selection, a);
};

onMounted(async () => {
    await $connection();

    $socket.on("queries:QueriesSelection:update", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("queries:QueriesSelection:update", (o) => {
        Object.assign(data, o);
    });
});

onUnmounted(async () => {
    $socket.removeListener("update-queries");
});
</script>
<script>
export default {
    path: "/queries",
    name: "##&en Queries ##&hu Lekérdezések ##",
    icon: "mdi-database-search",
    root: "Home",
};
</script>
