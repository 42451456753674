// /srv/codepad-project/@eventhandler-modules/actions/vue3/vuetiform-components/EventhandlerJavascript.vue
import EventhandlerJavascript from './src/vuetiform-components/EventhandlerJavascript.vue';

// /srv/codepad-project/@eventhandler-modules/eventhandlereditor/vue3/vuetiform-components/EventhandlerContextkeySelect.vue
import EventhandlerContextkeySelect from './src/vuetiform-components/EventhandlerContextkeySelect.vue';

// /srv/codepad-project/@eventhandler-modules/eventhandlereditor/vue3/vuetiform-components/EventhandlerContextkeySolvableText.vue
import EventhandlerContextkeySolvableText from './src/vuetiform-components/EventhandlerContextkeySolvableText.vue';

// /srv/codepad-project/@eventhandler-modules/eventhandlereditor/vue3/vuetiform-components/EventhandlerContextkeySolvableTextCombobox.vue
import EventhandlerContextkeySolvableTextCombobox from './src/vuetiform-components/EventhandlerContextkeySolvableTextCombobox.vue';

// /srv/codepad-project/@eventhandler-modules/eventhandlereditor/vue3/vuetiform-components/EventhandlerEvaluate.vue
import EventhandlerEvaluate from './src/vuetiform-components/EventhandlerEvaluate.vue';

// /srv/codepad-project/@database-modules/datafields/vue3/vuetiform-components/DatabaseDocument.vue
import DatabaseDocument from './src/vuetiform-components/DatabaseDocument.vue';

// /srv/codepad-project/@database-modules/datafields/vue3/vuetiform-components/DatabaseDocumentList.vue
import DatabaseDocumentList from './src/vuetiform-components/DatabaseDocumentList.vue';

// /srv/codepad-project/@database-modules/dataview/vue3/vuetiform-components/DatabaseCollectionSelector.vue
import DatabaseCollectionSelector from './src/vuetiform-components/DatabaseCollectionSelector.vue';

// /srv/codepad-project/@database-modules/dataview/vue3/vuetiform-components/DatabaseDocumentSelector.vue
import DatabaseDocumentSelector from './src/vuetiform-components/DatabaseDocumentSelector.vue';

// /srv/codepad-project/@database-modules/queries/vue3/vuetiform-components/QueriesForm.vue
import QueriesForm from './src/vuetiform-components/QueriesForm.vue';

// /srv/codepad-project/@database-modules/queries/vue3/vuetiform-components/VuetiformQueriesForm.vue
import VuetiformQueriesForm from './src/vuetiform-components/VuetiformQueriesForm.vue';

// /srv/codepad-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/FileDisplay.vue
import FileDisplay from './src/vuetiform-components/FileDisplay.vue';

// /srv/codepad-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/VuetiformFile.vue
import VuetiformFile from './src/vuetiform-components/VuetiformFile.vue';

// /srv/codepad-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/VuetiformFilelist.vue
import VuetiformFilelist from './src/vuetiform-components/VuetiformFilelist.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/HashtagsCombobox.vue
import HashtagsCombobox from './src/vuetiform-components/HashtagsCombobox.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/MeasurementTextfield.vue
import MeasurementTextfield from './src/vuetiform-components/MeasurementTextfield.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformArray.vue
import VuetiformArray from './src/vuetiform-components/VuetiformArray.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDate.vue
import VuetiformDate from './src/vuetiform-components/VuetiformDate.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDuration.vue
import VuetiformDuration from './src/vuetiform-components/VuetiformDuration.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDynaform.vue
import VuetiformDynaform from './src/vuetiform-components/VuetiformDynaform.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformJavascript.vue
import VuetiformJavascript from './src/vuetiform-components/VuetiformJavascript.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformList.vue
import VuetiformList from './src/vuetiform-components/VuetiformList.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformMathjs.vue
import VuetiformMathjs from './src/vuetiform-components/VuetiformMathjs.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformObject.vue
import VuetiformObject from './src/vuetiform-components/VuetiformObject.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformPoliform.vue
import VuetiformPoliform from './src/vuetiform-components/VuetiformPoliform.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformPretext.vue
import VuetiformPretext from './src/vuetiform-components/VuetiformPretext.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformServerform.vue
import VuetiformServerform from './src/vuetiform-components/VuetiformServerform.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformTemplateLiterals.vue
import VuetiformTemplateLiterals from './src/vuetiform-components/VuetiformTemplateLiterals.vue';

export default { EventhandlerJavascript, EventhandlerContextkeySelect, EventhandlerContextkeySolvableText, EventhandlerContextkeySolvableTextCombobox, EventhandlerEvaluate, DatabaseDocument, DatabaseDocumentList, DatabaseCollectionSelector, DatabaseDocumentSelector, QueriesForm, VuetiformQueriesForm, FileDisplay, VuetiformFile, VuetiformFilelist, HashtagsCombobox, MeasurementTextfield, VuetiformArray, VuetiformDate, VuetiformDuration, VuetiformDynaform, VuetiformJavascript, VuetiformList, VuetiformMathjs, VuetiformObject, VuetiformPoliform, VuetiformPretext, VuetiformServerform, VuetiformTemplateLiterals };