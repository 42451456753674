<template>
    <v-card v-if="data.error"><v-alert type="error" :title="data.error" icon="mdi-alert-circle" variant="tonal"></v-alert></v-card>
    <div v-else>
        <v-container fluid class="pt-0 pb-0">
            <v-btn
                :disabled="!props.host || props.host === hostname"
                :color="!props.host || props.host === hostname ? 'gray' : 'primary'"
                @click="openHostCollection()"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database</v-icon>Server</p>
            </v-btn>

            <v-btn @click="databaseClick()" variant="elevated" color="primary" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database</v-icon>##&en Database ##&hu Adatbázis ##</p>
            </v-btn>
            <v-btn
                :disabled="data.readonly"
                @click.native="
                    (e) => {
                        openDocument(e, null);
                    }
                "
                variant="elevated"
                color="primary"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon light class="pr-3">mdi-database-plus</v-icon>##&en Add Document ##&hu Új Dokumentum ##</p>
            </v-btn>
            <v-btn
                @click="removeDocuments()"
                @dblclick="removeDocuments()"
                variant="elevated"
                :color="getRemoveColor()"
                class="ma-1"
                :disabled="props.readonly || data.selection.size === 0"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3"> mdi-delete </v-icon>##&en Delete ##&hu Törlés ##</p>
            </v-btn>
            <v-btn @click="data.showall = !data.showall" :color="data.showall ? 'primary' : 'red'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none">[ {{ data.selection.size }}/{{ props.documents.length }} ##&en selected ##&hu kiválasztva ## ]</p>
            </v-btn>
            <v-btn @click="selectAll()" color="primary" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-select-all</v-icon>##&en Select all ##&hu Minden kijelölése ##</p>
            </v-btn>
            <v-btn @click="invertSelection()" color="primary" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-select-inverse</v-icon>##&en Invert ##&hu Inverz ##</p>
            </v-btn>
            <v-btn @click="selectNone()" color="primary" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-select</v-icon>##&en Select none ##&hu Kijelölés törlése ##</p>
            </v-btn>
            <v-btn @click="selectTypeChange()" color="primary" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none">
                    <v-icon dark class="pr-3">{{ selectType().icon }}</v-icon
                    >{{ selectType().title }}
                </p>
            </v-btn>
            <collection-table-patch-dialog v-if="!props.readonly" :database="database" :collection="collection" :selection="data.selection" :format="props.format" />
            <collection-table-export-import :database="database" :collection="collection" :readonly="props.readonly" />
        </v-container>
        <v-table fixed-header :height="height" class="pa-0" density="compact" :hover="true" :key="data.key">
            <thead>
                <tr
                    v-on:dragstart="(event) => drag(event, 0)"
                    v-on:dragover="(event) => over(event, 0)"
                    v-on:dragend="(event) => drop(event, 0)"
                    :class="data.sortover === 0 ? 'sort-over' : 'sort-other'"
                >
                    <th v-if="props.format.active" style="width: 10px">##&en Active ##&hu Aktív ##</th>
                    <th v-for="(v, k, i) in getFields()" @click="lexicalSelection(k)" class="text-left font-weight-bold"><v-icon :icon="getLexicalSortIcon(k)" />{{ v }}</th>
                    <th class="last-th">#</th>
                </tr>
            </thead>
            <tbody class="unselectable">
                <tr
                    v-for="(document, i) in getDocumentList()"
                    :key="document._id"
                    @click="
                        (e) => {
                            click(e, document, i);
                        }
                    "
                    :draggable="!props.readonly && props.sortable"
                    v-on:dragstart="(event) => drag(event, document._id)"
                    v-on:dragover="(event) => over(event, document._id)"
                    v-on:dragend="(event) => drop(event, document._id)"
                    :class="{ 'sort-over': data.sortover === document._id, 'sort-other': data.sortover !== document._id, selected: data.selection.has(document._id) }"
                >
                    <td v-if="props.format.active" @click.native.stop="toggleActive(document)">
                        <div class="checkbox pt-1 pl-1"><v-icon :icon="getActivatorIcon(document)" /></div>
                    </td>
                    <td v-for="(v, k, j) in getFields()">
                        <div
                            @contextmenu.prevent="
                                (e) => {
                                    clickField(e, document, i, v, k, j);
                                }
                            "
                        >
                            <pre>{{ getFieldText(document, i, v, k, j) }}</pre>
                        </div>
                    </td>
                    <td class="last-th">{{ (document._hashtags || []).join(" ") }}</td>
                </tr>
            </tbody>
        </v-table>
    </div>
    <v-dialog v-model="data.fieldDialog" width="600">
        <v-card class="ma-3 pa-3" :key="field.key">
            <vuetiform-component
                :format="field.format"
                :bond="{ module: 'dataview', page: 'CollectionTable', database, collection }"
                v-model="field.document"
                v-model:valid="field.valid"
                :disabled="field.readonly"
            />
            <v-btn @click="fieldEntry()" color="primary" variant="elevated" class="ma-1" min-width="200" :disabled="field.readonly" :color="field.readonly ? 'gray' : 'primary'">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database-check-outline</v-icon>##&en Save ##&hu Mentés ##</p>
            </v-btn>
        </v-card>
    </v-dialog>
    <v-dialog v-model="data.documentDialog">
        <v-card :key="field.key">
            <v-toolbar dark color="primary">
                <v-spacer></v-spacer>
                <v-btn icon dark @click="data.documentDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <document-view
                :bond="{ module: 'dataview', page: 'CollectionTable', database, collection, id: data.documentDialogId }"
                :database="database"
                :collection="collection"
                :id="data.documentDialogId"
            />
        </v-card>
    </v-dialog>
</template>

<script setup>
import CollectionTablePatchDialog from "@/database-components/CollectionTablePatchDialog.vue";
import CollectionTableExportImport from "@/database-components/CollectionTableExportImport.vue";
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import DocumentView from "@/database-components/DocumentView.vue";

import { ref, onMounted, onUnmounted, reactive, computed, watch, toRaw } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["host", "database", "collection", "documents", "format", "height", "readonly", "sortable", "selectOne", "selectMultiple", "modelValue"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const database = props.database;
const collection = props.collection;
const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

function initSelection() {
    let arr = [];
    if (props.selectOne || props.selectMultiple) arr = props.modelValue;
    if (!Array.isArray(arr)) arr = [arr];
    return arr;
}
const data = reactive({
    sortover: null,
    sortitem: null,
    selection: new Set(initSelection()),
    showall: true,
    removal: false,
    lexicalfield: null,
    lexicalreverse: false,
    key: 0,
    ctrl: false,
    shift: false,
    alt: false,
    lastShiftClick: null,
    fieldDialog: false,
    documentDialog: false,
    documentDialogKey: 0,
    documentDialogId: null,
});

const field = reactive({
    document: {},
    format: {},
    bond: {},
    value: null,
    valid: true,
    key: 0,
    readonly: false,
});

// drag and drop for sorting
function over(ev, id) {
    if (props.readonly) return;
    if (!props.sortable) return;
    ev.preventDefault();
    data.sortover = id;
}

function drag(ev, fromId) {
    if (props.readonly) return;
    if (!props.sortable) return;
    data.sortitem = fromId;
}

function drop(ev, id) {
    if (props.readonly) return;
    if (!props.sortable) return;
    ev.preventDefault();
    const fromId = data.sortitem;
    const toId = data.sortover;

    if (fromId === toId) {
        data.sortitem = null;
        data.sortover = null;
        return;
    }

    $socket.emit("dataview:CollectionTable:sortingDrop", { database, collection, fromId, toId }, () => {
        data.sortitem = null;
        data.sortover = null;
    });
}
function selectTypeChange() {
    if (!data.shift && !data.ctrl) return (data.shift = true);
    if (data.shift && !data.ctrl) return (data.ctrl = true);
    data.shift = false;
    data.ctrl = false;
}
function selectType() {
    if (data.ctrl)
        return {
            title: "##&en Select Multiple ##&hu Többszörös ##",
            icon: "mdi-select-multiple",
        };
    if (data.shift)
        return {
            title: "##&en Select Range ##&hu Tartomány ##",
            icon: "mdi-select-place",
        };
    if (data.alt)
        return {
            title: "##&en Edit ##&hu Szerkesztés ##",
            icon: "mdi-select-place",
        };
    if (props.selectOne || props.selectMultiple)
        return {
            title: "##&en Select ##&hu Kiválasztás ##",
            icon: "mdi-select",
        };
    return {
        title: "##&en Open ##&hu Megnyitás ##",
        icon: "mdi-open-in-app",
    };
}
function click(e, document, i) {
    const _id = document._id;
    if (e.shiftKey) {
        if (!data.lastShiftClick) {
            data.selection.add(_id);
            data.lastShiftClick = _id;
            return;
        }

        const array = getDocumentList().map((e) => e._id);

        const id_1 = data.lastShiftClick;
        const id_2 = _id;
        let inselection = false;

        for (const e of array) {
            if (inselection) data.selection.add(e);
            if (e === id_1 || e === id_2) inselection = !inselection;
        }

        updateSelection();
        return;
    }

    if (e.ctrlKey || e.metaKey) {
        if (data.selection.has(_id)) {
            data.selection.delete(_id);
            updateSelection();
            return;
        }
        data.selection.add(_id);
        updateSelection();
        return;
    }

    if (e.altKey) {
        data.documentDialog = true;
        data.documentDialogKey++;
        data.documentDialogId = _id;
        return;
    }

    if (props.selectOne) {
        let datum = document._id;
        emit("update", datum);
        emit("update:modelValue", datum);
        return;
    }
    if (props.selectMultiple) {
        updateSelection();
        return;
    }

    return openDocument(e, document);
}

let updateSelection = () => {};
if (props.selectMultiple)
    updateSelection = () => {
        if (!props.selectMultiple) return;
        let datum = [...data.selection];
        emit("update", datum);
        emit("update:modelValue", datum);
    };

function clickField(e, document, i, v, k, j) {
    field.key++;
    data.fieldDialog = true;
    Object.assign(field, {
        document,
        format: {
            is: "vuetiform-poliform",
            format: { [k]: { ...props.format[k], autofocus: true } },
        },
        bond: {},
        valid: true,
        readonly: props.readonly || document.readonly,
    });
}

function fieldEntry() {
    $socket.emit("dataview:CollectionTable:fieldEntry", { database, collection, document: field.document }, () => {
        data.fieldDialog = false;
    });
}

function handleMouseDown(event) {}
function handleKeyDown(event) {
    if (event.key === "Alt") data.alt = true;
    if (event.key === "Shift") data.shift = true;
    if (event.key === "Meta" || event.key === "Ctrl") data.ctrl = true;
}
function handleKeyUp(event) {
    if (event.key === "Alt") data.alt = false;
    if (event.key === "Shift") {
        data.shift = false;
        data.lastShiftClick = null;
    }
    if (event.key === "Meta" || event.key === "Ctrl") data.ctrl = false;
}

function databaseClick() {
    router.push("/database/" + database);
}

function getLexicalSortIcon(key) {
    if (key === data.lexicalfield)
        if (!data.lexicalreverse) return "mdi-triangle-small-down";
        else return "mdi-triangle-small-up";
    if (data.lexicalfield) return "mdi-minus";
    return "mdi-circle-small";
}

function lexicalSelection(key) {
    if (data.lexicalfield !== key) {
        data.lexicalfield = key;
        data.lexicalreverse = false;
        return;
    }
    if (!data.lexicalreverse) return (data.lexicalreverse = true);
    data.lexicalfield = null;
}

function lexicalSort() {
    if (data.lexicalfield === null) return props.documents;
    const key = data.lexicalfield;
    const sorted = clone(props.documents).sort((a, b) => {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
    });

    if (data.lexicalreverse) return sorted.reverse();
    return sorted;
}

function getDocumentList() {
    return selectionFilter(lexicalSort());
}

function selectionFilter(documents) {
    if (data.showall) return documents;
    return documents.filter((document) => {
        return data.selection.has(document._id);
    });
}

function selectAll() {
    for (const document of props.documents) data.selection.add(document._id);
    updateSelection();
}
function selectNone() {
    data.selection.clear();
    updateSelection();
}
function invertSelection() {
    for (const document of props.documents)
        if (data.selection.has(document._id)) data.selection.delete(document._id);
        else data.selection.add(document._id);
    updateSelection();
}

function getFields() {
    const fields = {};
    for (const f in props.format) if (props.format[f].header) fields[f] = props.format[f].header;

    return fields;
}

function getFieldText(document, i, v, k, j) {
    const format = props.format[k];
    const context = { document, datafield: { format } };
    let value = document[k];

    /*if (typeof format["Boolean:if"] === "function")
      	// if (format["Boolean:if"].getFunctionWithContext(context)(value) == false) {
        if (format["Boolean:if"].bind(context)(value) == false) {
            if (value !== undefined) return "---";
            return "";
        }*/

    if (typeof format.getTextValue === "function") value = format.getTextValue.bind(context)(value) || "";
    else value = (value || "").toString();
    if (value.length > 250) return value.substring(0, 250) + "...";
    return value;
}

function getActivatorIcon(document) {
    if (!document) return "mdi-checkbox-blank-off-outline";
    if (document.active === true) return "mdi-checkbox-outline";
    if (document.active === false) return "mdi-checkbox-blank-outline";
    return "mdi-checkbox-blank-off-outline";
}

const toggleActive = (document) => {
    if (props.readonly) return;
    $socket.emit("dataview:CollectionTable:toggleActivator", { database, collection, document }, () => {});
};

const openCollection = () => {
    if (data.host) return (window.location.href = "https://" + data.host + "/document/" + database + "/" + collection);
    router.push("/database/" + database + "/" + collection);
};
const openHostCollection = () => {
    window.location.href = "https://" + props.host + "/database/" + database + "/" + collection;
};
const openDocument = (e, document) => {
    $socket.emit("dataview:CollectionTable:openDocument", { database, collection, id: (document || {})._id }, ({ database, collection, id }) => {
        if (e.altKey || props.selectOne || props.selectMultiple) {
            data.documentDialog = true;
            data.documentDialogKey++;
            data.documentDialogId = id;
            return;
        }
        if (!database || !collection || !id) return;
        router.push("/document/" + database + "/" + collection + "/" + id);
    });
};

const getRemoveColor = () => {
    if (props.readonly || data.selection.size === 0) return "gray";
    if (data.removal) return "red";
    return "primary";
};

const removeDocuments = () => {
    if (!data.removal) {
        data.removal = true;
        setTimeout(() => {
            data.removal = false;
        }, 2000);
        return;
    }
    const selection = Array.from(data.selection);
    $socket.emit("dataview:CollectionTable:removeDocuments", { database, collection, selection }, () => {
        data.removal = false;
        data.selection.clear();
        updateSelection();
    });
};
const resizedHeight = ref(0);
const onResize = () => {
    resizedHeight.value = window.innerHeight - 460;
};
onMounted(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
});

onUnmounted(() => {
    document.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("keyup", handleKeyUp);
});
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
td:hover {
    cursor: pointer;
}
.checkbox {
    /*background-color: red;*/
    height: 35px;
}
.last-th {
    max-width: 150px; /* Set your desired maximum width here */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.sort-over {
    box-shadow: 0px 2px 0px rgb(var(--v-theme-info));
}
.selected {
    background-color: rgba(var(--v-theme-primary), 0.5) !important;
}
.unselectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
</style>
