<template>
    <v-autocomplete
      	class="pt-3"
      	:label="props.label"
        :items="props.items"
        v-model="data.value"
        :disabled="props.disabled"
        prepend-icon="mdi-database-edit"
        @click:prepend="databaseEdit"
        @update:modelValue="updateHandler()"
    >
    </v-autocomplete>
    <v-dialog v-model="dialog.open" @update:modelValue="updateHandler()">
        <v-card :key="props.database + '.' + props.collection">
            <v-toolbar dark color="primary">
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog.open = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <collection-view
                :database="database"
                :collection="collection"
                :selectOne="true"
                v-model="data.value"
                @update="
                    updateHandler();
                    dialog.open = false;
                "
            />
        </v-card>
    </v-dialog>
</template>
<script setup>
import CollectionView from "@/database-components/CollectionView.vue";

import { ref, reactive, watch, nextTick, toRaw, onMounted } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["modelValue", "is", "label", "clearable", "hint", "mandatory", "items", "readonly", "disabled", "database", "collection"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ items: props.items || [], value: props.modelValue || null});

const dialog = reactive({
    open: false,
});

const databaseEdit = () => {
    dialog.open = true;
};

async function updateHandler() {
    await nextTick();
    let datum = clone(data.value);
    emit("update", datum);
    emit("update:modelValue", datum);
    validHandler();
}

async function refresh() {
    Object.assign(data, { items: props.items || [], value: clone(props.modelValue) });
    validHandler();
}
  
defineExpose({ refresh });

function isValid() {
  	if (!data.value) return true;
    return data.items.map((e) => e.value).includes(data.value);
}

function validHandler() {
    emit("update:valid", isValid());
}

onMounted(async () => {
    validHandler();
});
</script>
<script>
export default {
    inheritAttrs: false,
};
</script>
