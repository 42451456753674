function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

function isFunctionKey(key) {
    if (["getTextValue", "setFieldValue"].includes(key)) return true;
    if (key.includes(":")) return true;
    return false;
}

import getFunction from "@/vuetiform/getFunction.mjs";
export default function formatFunctions(format = {}) {
    const traverse = (input) => {
        if (!isObject(input)) return input;
        const functionkeys = [];
        const datafield = Object.entries(input).reduce((acc, [key, value]) => {
            if (isFunctionKey(key) && typeof value !== "function") {
                // value is the code representation in array of strings format
                const executeFunction = getFunction.bind(this)(value);
                acc[key] = executeFunction;
                acc[key + "-function"] = value;
                functionkeys.push(key);
            } else if (isObject(value)) {
                acc[key] = traverse(value); // Recursively call traverse for nested objects
            } else {
                acc[key] = value; // Copy value directly for non-object and non-functionKey cases
            }
            return acc;
        }, {});

        /*for (const key of functionkeys) {
            const executeFunction = datafield[key];
            datafield[key] = executeFunction.bind({ datafield, path: path ? path + "." + key : key });
          	datafield[key].getFunctionWithContext = executeFunction.getFunctionWithContext;
        }*/

        functionkeys.forEach(key => { datafield[key] = datafield[key].bind({ datafield }); })

        return datafield;
    };
    return traverse(format);
}

// ****/
// DocumentView 150
// CollectionView 100
