<template>
    <v-autocomplete
        v-model="data.list"
        multiple
        chips
        :items="data.items"
        @click:append="clickAppend()"
        v-bind="reprops()"
        @update:modelValue="updateHandler()"
        @update:menu="updateMenu"
        :disabled="props.disabled"
        prepend-icon="mdi-database-edit"
        @click:prepend="databaseEdit"
        v-model:menu="data.isMenuOpen"
    >
        <template v-slot:chip="{ props, item, index }">
            <v-chip size="large" v-bind="props" @click="clickChip(index)"
                ><v-icon v-if="data.isMenuOpen" class="ml-0" start :icon="getChipIcon(index)"></v-icon> {{ item.title }}
            </v-chip>
        </template>
        <template v-slot:prepend-item>
            <v-list-item>
                <v-row class="mx-0" align="center" justify="space-between">
                    <v-col cols="auto" class="d-flex">
                        <v-btn @click="left()" :disabled="!listValid()" class="mr-2">
                            <span class="hidden-sm-and-down">##&en Left ##&hu Balra ##</span>
                            <v-icon end>mdi-arrow-left-box</v-icon>
                        </v-btn>
                        <v-btn @click="right()" :disabled="!listValid()" class="mr-2">
                            <span class="hidden-sm-and-down">##&en Right ##&hu Jobbra ##</span>
                            <v-icon end>mdi-arrow-right-box</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col cols="auto">
                        <v-btn @click="remove()" :disabled="!listValid()">
                            <span class="hidden-sm-and-down">##&en Remove ##&hu Eltávolítás ##</span>
                            <v-icon end>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:no-data></template>
    </v-autocomplete>
    <v-dialog v-model="dialog.open" @update:modelValue="update()">
        <v-card :key="database + '.' + collection">
            <v-toolbar dark color="primary">
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog.open = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <collection-view :database="database" :collection="collection" :selectMultiple="true" v-model="data.list" @update="updateHandler()" />
        </v-card>
    </v-dialog>
</template>
<script setup>
import CollectionView from "@/database-components/CollectionView.vue";

import { ref, reactive, watch, nextTick, toRaw } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}
function isArray(i) {
    return Object.prototype.toString.call(i) === "[object Array]";
}

const emit = defineEmits(["update:modelValue", "update:valid", "update", "input"]);
const props = defineProps(["modelValue", "is", "class", "label", "clearable", "hint", "mandatory", "items", "readonly", "disabled", "database", "collection"]);

const reprops = () => {
    const re = clone(props);
    delete re.items;
    delete re.modelValue;
    re.multiple = true;
    re.chips = true;
    return re;
};

const dialog = reactive({
    open: false,
});

async function refresh() {
    Object.assign(data, { items: props.items || [], list: clone(props.modelValue || []) });
    validHandler();
}

defineExpose({ refresh });

const data = reactive({
    list: clone(props.modelValue || []),
    index: null,
    items: props.items || [],
    isMenuOpen: false,
});

const databaseEdit = () => {
    dialog.open = true;
};

const update = () => {};

const left = () => {
    if (data.index === null) return;
    if (data.index < 1) return;
    const i = data.index;
    const value = data.list[i];
    data.list[i] = data.list[i - 1];
    data.list[i - 1] = value;
    data.index--;
    updateHandler();
};
const right = () => {
    if (data.index === null) return;
    if (data.list.length - data.index < 2) return;
    const i = data.index;
    const value = data.list[i];
    data.list[i] = data.list[i + 1];
    data.list[i + 1] = value;
    data.index++;
    updateHandler();
};
const remove = () => {
    if (data.index === null) return;
    const i = data.index;
    if (i + 1 === data.list.length) data.index = data.index - 1;
    data.list.splice(i, 1);
    if (data.list.length < 1) data.index = null;
    updateHandler();
};

const listValid = () => {
    if (data.index === null) return false;
    if (data.index < data.list.length) return true;
    return false;
};

const getChipIcon = (i) => {
    if (data.index === i) return "mdi-circle";
    return "mdi-circle-outline";
};

const clickChip = (i) => {
  	data.isMenuOpen = true;
    data.index = i;
    updateHandler();
};

const isValid = () => {
    if (props.mandatory === true) if (data.list.length < 1) return "##&en At least one element must be index ##&hu legalább egy elem választása kötelező ##";
    const items = data.items.map((e) => e.value);
    for (const i of data.list) if (!items.includes(i)) return "##&en Invalid items ##&hu Érvénytelen bejegyzés ## " + i;
    return true;
};

const updateHandler = async () => {
    await nextTick();
    const valid = isValid();
    let datum = clone(data.list);

    emit("update", datum);
    emit("update:modelValue", datum);
    validHandler();
};

const validHandler = async () => {
    await nextTick();
    const valid = isValid();
    emit("update:valid", valid);
};

const updateMenu = (e) => {
    data.isMenuOpen = e;
    data.index = null;
};
</script>
<script>
export default {
    inheritAttrs: false,
};
</script>
