<template>
    <v-container class="codebox">
        <pre class="ma-1">{{ props.pretext }}</pre>
        <v-chip v-for="(chip, i) in getContextItems()" @click="drop(chip)" class="ma-1"> {{ chip }} </v-chip>
        <vue-codemirror v-model="data.value" @change="updateHandler" :extensions="extensions"/>
        <pre class="ma-1">{{ data.valid === true ? "" : "ERROR " + data.valid }}</pre>
    </v-container>
</template>

<script setup>
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
import { parse } from "acorn";

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "modelValue", "pretext", "contextkeys"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ value: clone(props.modelValue || ""), valid: true, error: null });

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    Object.assign(data, { value: clone(props.modelValue || ""), valid: isValid() });
    await nextTick();
}

defineExpose({ refresh });

function isValid() {
    try {
        parse(data.value || "", { ecmaVersion: 2022, sourceType: "module", allowReturnOutsideFunction: true });
        return true;
    } catch (e) {
        return e.message;
    }
}

async function updateHandler() {
    await nextTick();
    const datum = clone(data.value);
    const valid = isValid();
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", valid);
    data.valid = valid;
}

onMounted(async () => {
    data.valid = isValid();
});

// context related
function drop(chip) {
    if (data.value.length > 0) data.value += " + ";
    data.value += chip;
    updateHandler();
}

function getContextItems() {
    return props.contextkeys || [];
}
  
/// codemirror setup
import {
    keymap,
    highlightSpecialChars,
    drawSelection,
    highlightActiveLine,
    dropCursor,
    rectangularSelection,
    crosshairCursor,
    lineNumbers,
    highlightActiveLineGutter,
} from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { defaultHighlightStyle, syntaxHighlighting, indentOnInput, bracketMatching, foldGutter, foldKeymap } from "@codemirror/language";
import { defaultKeymap, history, historyKeymap } from "@codemirror/commands";
import { searchKeymap, highlightSelectionMatches } from "@codemirror/search";
import { autocompletion, completionKeymap, closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { lintKeymap } from "@codemirror/lint";
const basicSetup = (() => [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    history(),
    //foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    //closeBrackets(),
    autocompletion(),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    keymap.of([...closeBracketsKeymap, ...defaultKeymap, ...searchKeymap, ...historyKeymap, ...foldKeymap, ...completionKeymap, ...lintKeymap]),
])();

//-------------
const extensions = [keymap.of(defaultKeymap), basicSetup];

//import { linter, lintGutter } from "@codemirror/lint";
import { javascript } from "@codemirror/lang-javascript";
extensions.push(javascript());
//extensions.push(lintGutter());

//import { lintKeymap } from "@codemirror/lint";
//import { esLint } from "@codemirror/lang-javascript";
//import { tags as t } from "@lezer/highlight";

//import { oneDark } from "@codemirror/theme-one-dark";
//extensions.push(oneDark);

import { createTheme } from "thememirror";
import { cobalt } from "thememirror";
extensions.push(cobalt);

import { EditorView } from "@codemirror/view";
let highlightTheme = EditorView.theme(
    {
        ".cm-selectionMatch": {
            backgroundColor: "#FFF5",
        },
    },
    { dark: true },
);
const customSelectionStyle = EditorView.baseTheme({
    "&.cm-focused > .cm-scroller > .cm-selectionLayer .cm-selectionBackground": {
        backgroundColor: "#FFF4",
    },
    ".cm-selectionBackground": {
        backgroundColor: "#FFF4",
    },
});

extensions.push(customSelectionStyle);
extensions.push(highlightTheme);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-javascript",
};
</script>
<style>
.codebox {
}
</style>
