<template>
    <!--p class="ma-2 pl-2 text-h6" v-if="props.label">{{ props.label }}</p-->
    <vuetiform-component
        v-if="data.format"
        :bond="props.bond"
        :format="data.format"
        v-model="data.value"
        v-model:valid="data.valid"
        @update="updateHandlerCollector"
        @update:valid="validHandler"
        :ref="getRef('component')"
        :key="data.key"
    />
</template>

<script setup>
// dynamically load data from socket.io

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { compare } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, onUnmounted, toRaw } from "vue";

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "format", "modelValue", "identifier", "module", "channel", "label", "updateOn", "serverform"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ format: clone(props.format), value: clone(props.modelValue), valid: true, key: 0 });

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    if (refs.component) if (refs.component.refresh) await refs.component.refresh();
}

defineExpose({ refresh });

async function updateHandler(d, ...a) {
    await nextTick();
    const datum = clone(data.value);
    emit("update", datum, ...a);
    emit("update:modelValue", datum);
    validHandler();
}

let lastValidUpdate = null;
function validHandler() {
    const valid = data.valid;
    if (lastValidUpdate === valid) return;
    emit("update:valid", valid);
    lastValidUpdate = valid;
}

let collect = false;
async function updateHandlerCollector(event, ...a) {
    if (collect) return;
    collect = true;
    await nextTick();
    collect = false;
    await serverUpdate(event, ...a);
}

import formatFunctions from "@/vuetiform/formatFunctions.mjs";
function formatUpdate() {
  	const { serverform } = formatFunctions.call({ document: toRaw(props.bond?.document || {}), format: { serverform: data.format}, VuetiformServerform: true}, { serverform: data.format});
    //Ł("VuetiformServerform formatUpdate", serverform);
	Object.assign(data, { format: serverform });
}
  
async function serverUpdate(event, ...a) {
    await nextTick();
    return new Promise((resolve, reject) => {
        //Ł(props.module + ":" + props.channel + ":update", d, ...a, data.value);
        $socket.emit(props.module + ":" + props.channel + ":update", { props, data, event }, async (o) => {
            Object.assign(data, o);
          	if (o.format) formatUpdate();
            await nextTick();
            if (o.value) await updateHandler(null, { component: "VuetiformServerform", trigger: "serverUpdate", identifier: props.identifier });
            else await updateHandler(event, ...a);
            if (o.format) if (refs.component) if (refs.component.refresh) await refs.component.refresh();
            resolve();
            //Ł(event + " VuetiformServerform serverUpdate " + props.format);
        });
    });
}

onMounted(async () => {
    if (props.updateOn)
        $socket.on(props.updateOn, () => {
            serverUpdate(props.updateOn);
        });
    serverUpdate("onMounted");
});

onUnmounted(async () => {
    if (props.updateOn) $socket.removeListener(props.updateOn);
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-serverform",
};
</script>
