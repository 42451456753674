/*

Context:

	document
 	format
  	datafield.format

Argument: value

Caller (functionalValues({ format, value })) - takes the format and turns functional keys into functions.
	VuetiformComponent
	CollectionView
 
Compiler (format = formatFunction.call({..}, format))
	VuetiformServerform 
 	CollectionView
	DocumentView
 	server-side

return:
	setFieldValue -> document.value
 	getTextValue  -> Human readble text
 
*/
import { toRaw } from "vue";

export default function getFunction(code = ["return null;"]) {
    const that = this || {};

    const fn = new Function(...code);
    //Object.defineProperty(fn, "name", { value: "FunctionBody", writable: false });
    //Object.defineProperty(fn, "source", { value: toRaw(code), writable: false });
    const executeFunction = function executeFunction(...arg) {
        try {
            return fn.bind({ ...that, ...this })(...arg);
        } catch (err) {
            console.error("%cError in frontend-function", "color: red;");
            console.log("error:", err, "that", that, "this", this, "argument:", toRaw(...arg), "function:", fn);
            return "[ERROR]";
        }
    };

    //Object.defineProperty(fn, "name", { value: "executeFunction", writable: false });
    //Object.defineProperty(fn, "source", { value: toRaw(code), writable: false });

  	// VuetiformComponent.vue 55
  	// CollectionTable.vue 441
    // functionalValues.mjs 6
    /*executeFunction.getFunctionWithContext = function getFunctionWithContext(context) {
        return function executeFunction(...arg) {
            try {
                return fn.bind({ ...that, ...this, ...context })(...arg);
            } catch (err) {
                console.error("%cError in frontend-function with context", "color: red;");
                console.log("error:", err, "context", context, "that", that, "this", this, "argument:", toRaw(...arg), "function:", fn);
                return "[ERROR]";
            }
        };
    };*/

    return executeFunction;
}
