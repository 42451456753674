<template>
    <p class="ma-3 pt-2 text-h7" v-if="props.label">{{ props.label }}</p>
    <div v-for="(fx, x, i) in props.format">
        <vuetiform-component
            :bond="getBond(x)"
            :key="x + i"
            :format="fx"
            :modelValue="getTopValue(x)"
            @update="(...args) => setTopValue(x, ...args)"
            @update:valid="(...args) => setTopValid(x, ...args)"
            :disabled="props.disabled"
            :ref="getTopRef(x)"
        />
        <div v-if="fx.formats" v-for="(fz, z, k) in fx.formats[getTopValue(x)]">
            <vuetiform-component
                :bond="getBond([x, getTopValue(x), z])"
                :key="x + i + z + k + '-' + getTopValue(x)"
                :format="fz"
                :modelValue="getSubValue(x, getTopValue(x), z)"
                @update="(...args) => setSubValue(x, getTopValue(x), z, ...args)"
                @update:valid="(...args) => setSubValid(x, getTopValue(x), z, ...args)"
                :disabled="props.disabled"
                :ref="getSubRef(x, getTopValue(x), z)"
            />
        </div>
    </div>
</template>

<script setup>
// Object that can change shape depending on the formats parameter

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";

function clone(p) {
    return structuredClone(toRaw(p));
}

// identifier and index are needed for array index identification.
const props = defineProps(["bond", "format", "modelValue", "disabled", "identifier", "index", "label"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ key: 0 });

const object = reactive({});

function generate(format, modelValue) {
    for (const x in format) {
        object[x] = {
            value: modelValue[x] || format[x].default || undefined,
            valid: true,
            ref: null,
            key: 0,
            sub: {},
        };
        if (format[x].formats)
            for (const y in format[x].formats) {
                object[x].sub[y] = {};
                for (const z in format[x].formats[y]) {
                    let value = format[x].formats[y][z].default;
                    if (modelValue[x] !== undefined) if (modelValue[x] === y) value = modelValue[z];
                    object[x].sub[y][z] = {
                        value,
                        valid: true,
                        ref: null,
                        key: 0,
                    };
                }
            }
    }
}

generate(props.format, reactive(clone(props.modelValue || {})));

function getTopValue(x) {
    if (!object[x]) return;
    return object[x].value;
}

function getSubValue(x, y, z) {
    if (!object[x]) return;
    if (!object[x].sub[y]) return;
    if (!object[x].sub[y][z]) return;
    return object[x].sub[y][z].value;
}

function setTopValue(x, v, ...args) {
    if (!object[x]) object[x] = { valid: true, ref: null, key: 0, sub: {} };
    object[x].value = v;
    updateHandler(v, ...args);
    data.key++;
}

function setSubValue(x, y, z, v, ...args) {
    if (!object[x]) object[x] = { valid: true, ref: null, key: 0, sub: {} };
    if (!object[x].sub[y]) object[x].sub[y] = {};
    if (!object[x].sub[y][z]) object[x].sub[y][z] = { valid: true, ref: null, key: 0 };
    object[x].sub[y][z].value = v;
    updateHandler(v, ...args);
}

function setTopValid(x, v) {
    if (!object[x]) object[x] = { ref: null, key: 0, sub: {} };
    object[x].valid = v;
    validHandler();
}

function setSubValid(x, y, z, v) {
    if (!object[x]) object[x] = { valid: true, ref: null, key: 0, sub: {} };
    if (!object[x].sub[y]) object[x].sub[y] = {};
    if (!object[x].sub[y][z]) object[x].sub[y][z] = { valid: true, ref: null, key: 0 };
    object[x].sub[y][z].valid = v;
    validHandler();
}
function getTopRef(x) {
    return (el) => {
        if (!object[x]) object[x] = { valid: true, ref: null, key: 0, sub: {} };
        object[x].ref = el;
    };
}

function getSubRef(x, y, z) {
    return (el) => {
        if (!object[x]) object[x] = { valid: true, ref: null, key: 0, sub: {} };
        if (!object[x].sub[y]) object[x].sub[y] = {};
        if (!object[x].sub[y][z]) object[x].sub[y][z] = { valid: true, ref: null, key: 0 };
        object[x].sub[y][z].ref = el;
    };
}
function getBond(index) {
    if (!props.bond) return;
    const bond = props.bond || {};
    if (bond.stack && props.identifier) {
        const stack = Array.from(bond.stack);
        stack.push({ [props.identifier]: index });
        return { ...bond, stack };
    }
    return bond;
}

function getValue() {
    const format = props.format;
    let value = {};
    for (const x in format) {
        if (object[x] === undefined) return;
        value[x] = clone(object[x].value);
        if (format[x].formats)
            for (const y in format[x].formats) {
                if (format[x].formats[y])
                    if (getTopValue(x) == y)
                        for (const z in format[x].formats[y]) {
                            value[z] = clone(object[x].sub[y][z].value);
                        }
            }
    }
    return value;
}
function isValid() {
    const format = props.format;
    for (const x in format) {
        if (object[x] === undefined) return true;
        if (object[x].valid !== true) return object[x].valid;
        if (format[x].formats)
            for (const y in format[x].formats) {
                if (format[x].formats[y])
                    if (getTopValue(x) == y)
                        for (const z in format[x].formats[y]) {
                            if (object[x].sub[y][z].valid !== true) return object[x].sub[y][z].valid;
                        }
            }
    }

    return true;
}

let collect = false;
async function updateHandler(d, ...a) {
    if (collect) return;
    collect = true;
    await nextTick();
    collect = false;
    const datum = { ...props.modelValue, ...getValue() };
    emit("update", datum, ...a);
    emit("update:modelValue", datum);
    validHandler();
}

let lastValidUpdate = null;
function validHandler() {
    const valid = isValid();
    if (lastValidUpdate === valid) return;
    emit("update:valid", valid);
    lastValidUpdate = valid;
}

async function refresh() {
    generate(props.format, reactive(clone(props.modelValue || {})));
    await nextTick();

    const format = props.format;
    for (const x in format) {
        const r = object[x].ref;
        if (r) if (r.refresh) await r.refresh();

        if (format[x].formats)
            for (const y in format[x].formats) {
                if (format[x].formats[y])
                    for (const z in format[x].formats[y]) {
                        const r = object[x].sub[y][z].ref;
                        if (r) if (r.refresh) await r.refresh();
                    }
            }
    }
}

defineExpose({ refresh });

onMounted(async () => {
    validHandler();
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-poliform",
};
</script>
