<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="6">
                <v-select prepend-icon="mdi-database" :items="databaseItems()" v-model="data.database" @update:modelValue="databaseChange()" />
            </v-col>
            <v-col cols="12" md="6">
                <v-select prepend-icon="mdi-database-outline" :items="collectionItems()" v-model="data.collection" @update:modelValue="collectionChange()" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup>
import { ref, reactive, watch, nextTick } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";

const emit = defineEmits(["update:modelValue", "update:valid", "update", "input"]);
const props = defineProps(["list", "modelValue", "mandatory", "database_collection", "is", "class"]);

const modelValue = props.modelValue || {};

const data = reactive({
    database: modelValue.database || null,
    collection: modelValue.collection || null,
});

const databaseItems = () => {
    if (!props.list) return [];
    return Object.keys(props.list);
};
const collectionItems = () => {
    const database = data.database;
    if (!database) return [];
    if (!props.list) return [];
    return Object.keys(props.list[database]);
};

const isValid = () => {
    if (!data.database) return "##&en Database selection ##&hu Adatbázis választás ##";
    if (!data.collection) return "##&en Database selection ##&hu Adatbázis választás ##";
    return true;
};

const databaseChange = () => {
    data.collection = null;
    updateHandler();
};
const collectionChange = () => {
    updateHandler();
};

const updateHandler = async () => {
    await nextTick();
    let datum = null;
    const database = data.database;
    const collection = data.collection;
    if (database && collection) datum = clone({ database, collection });
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};
</script>
