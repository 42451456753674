<template>
    <v-container v-if="props.grid" fluid class="pa-0">
        <v-row no-gutters>
            <v-col v-for="(o, key, i) in format" :key="i">
                <v-sheet :min-width="props.elementMinWidth || 200"
                    ><vuetiform-component                       	
                        :key="i"
                        :format="o"
                        v-model="object[key].value"
                        v-model:valid="object[key].valid"
                        @update="updateHandler(key)"
                        @update:modelValue="updateHandler(key)"
                        :disabled="props.disabled"
                /></v-sheet>
            </v-col>
        </v-row>
    </v-container>
  	<div v-else v-for="(o, key, i) in format">
        <vuetiform-component :key="i" :format="o" v-model="object[key].value" v-model:valid="object[key].valid" @update="updateHandler(key)" @update:modelValue="updateHandler(key)" :disabled="props.disabled" />
    </div>
</template>

<script setup>
// for now, only the template differs

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw, computed } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["format", "modelValue", "disabled", "elementMinWidth", "grid"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const object = reactive({});

function generate(format, modelValue) {
    for (const key in format)
        object[key] = {
            value: modelValue[key],
            valid: true,
            ref: null,
        };
}

generate(props.format, reactive(clone(props.modelValue || {})));

function getRef(key) {
    return (el) => (object[key].ref = el);
}

async function refresh() {
    for (const key in object) if (object[key].ref) if (object[key].ref.refresh) await object[key].refresh();
}

defineExpose({ refresh });

const valid = reactive({});
const isValid = () => {
    for (const key in object) if (object[key].valid !== true) return key + ": " + object[key].valid;
    return true;
};

const updateHandler = async (from) => {
    await nextTick();
    const datum = {};
    for (const key in props.format) datum[key] = clone(object[key].value);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};

onMounted(async () => {
    emit("update:valid", isValid());
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-object",
};
</script>
