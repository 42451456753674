<template>
    <v-dialog v-model="dialog.open" width="auto">
        <template v-slot:activator="{ props }">
            <v-btn size="large" v-bind="props" icon>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>

        <v-card min-width="600">
            <v-card-actions>
                <span class="text-h5 pa-6">{{ props.label }}</span>
                <v-spacer />
                <v-btn size="large" @click="dialog.open = false" icon="mdi-close" />
            </v-card-actions>
            <v-card-text>
                <v-container>
                    <div v-for="(definition, key, i) in props.format">
                        <vuetiform-component
                            :key="i"
                            :format="definition"
                            v-model="data[key]"
                            v-model:valid="valid[key]"
                            @update="updateHandler(key)"
                            :disabled="props.disabled"
                          	:bond="{ data }"
                        />
                    </div>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="bg-primary" :disabled="isValid() !== true" size="large" icon @click="clickEnter()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";
import { isProxy, toRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone as clone } from "../../helper-functions.mjs";

const props = defineProps(["format", "icon", "values", "label"]);
const emit = defineEmits(["update", "clickEnter"]);

const dialog = reactive({
    open: false,
});

const data = reactive(clone(props.values || {}));

// validators
const valid = reactive({});
const isValid = () => {
    for (const i in valid) if (valid[i] !== true) return i + ": " + valid[i];
    return true;
};

const updateHandler = async (from) => {
    await nextTick();
    const datum = clone(data);
    emit("update", datum);
};
const clickEnter = async (from) => {
    await nextTick();
    const datum = clone(data);
    emit("clickEnter", datum);
};
</script>
<script>
export default {
    inheritAttrs: false,
    name: "vuetifind-dialog",
};
</script>
