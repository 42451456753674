<template>
    <div v-if="data.condition" :class="{ valid: isValid() === true, invalid: isValid() !== true, readonly: data.format.readonly === true }">
        <component
            :is="data.format.is"
            :bond="props.bond"
            v-bind="data.format"
            v-model="data.value"
            v-model:valid="data.valid"
            @input="updateHandler"
            @update="updateHandler"
            @update:modelValue="updateHandler"
            @update:valid="validHandler"
            @click:clear="clearHandler"
            class="pa-3"
            :ref="getRef('component')"
        >
        </component>
    </div>
</template>

<script setup>
// this components goal is to merge vuetify and vuetiform components, and add a layer of common extra functionality
import { ref, reactive, watch, nextTick, toRaw, onMounted } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "format", "modelValue", "disabled", "readonly"]);

import functionalValues from "@/vuetiform/functionalValues.mjs";

const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ format: toRaw(props.format), value: toRaw(props.modelValue), condition: true });

function updateFunctionalvalues() {
    const document = props.bond?.document || {};
    const context = { document, datafield: toRaw(props.format) || {}, VuetiformComponent: true };

    functionalValues.call(context, data);

    if (data.format.condition)
        if (data.format.condition.hashtags) {
            const tags = data.format.condition.hashtags;
            if ((document._hashtags || []).some((tag) => tags.includes(tag))) {
                data.condition = true;
            } else {
                data.condition = false;
                if (data.value !== undefined) {
                    data.value = undefined;
                    updateHandler();
                }
            }
        }

    if (!data.format.setFieldValue) return;
    if (typeof data.format.setFieldValue !== "function") return console.error("%cError in format.setFieldValue, not a function ", "color: red;");
    const value = data.format.setFieldValue.bind(context)(data.value);

    if (value === data.value) return;
    data.value = value;
    updateHandler();
}

data.valid = true;

// :ref="getRef('component')"
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    updateFunctionalvalues();

    await nextTick();
    if (refs.component) if (refs.component.refresh) await refs.component.refresh();
    validHandler();
}

if (!props.format) {
    console.log(toRaw(props));
    console.error("%cError: Vuetiform component must have a format !!", "color: red;");
}

if (props.disabled) data.format.disabled = true;
if (props.readonly) data.format.readonly = true;

import validators from "../../validators.mjs";
if (data.format) {
    const format = data.format;
    if (format.validators || format.regex) {
        format.rules = [];
        let list = format.validators || [];
        if (typeof format.validators === "string") list = format.validators.split("|");
        //if (format.mandatory === true) list.push("validateMandatoryField");
        for (const i of [...new Set(list)])
            format.rules.push((input) => {
                const r = validators[i](input);
                if (r === true) return true;
                if (r === false) return "Invalid by " + i;
                return r;
            });
        if (format.regex)
            format.rules.push((str) => {
                const re = new RegExp(format.regex, "i");
                if (re.test(str) == false) return "##&en Regex check failed. ##&hu regex ellenőrzés hibajelzés ##";
                return true;
            });
    }
}
const isValid = () => {
    const format = data.format;
    if (!format) return true;
    if (data.valid !== true) return data.valid;
  	if (format.condition === false) return true;
    if (format.readonly === true) return true;
    if (format.mandatory === true) {
      if (data.value === undefined || data.value === null) return (format.label || "") + " ##&en Mandatory field! ##&hu Kötelező mező! ##";
      if (data.value === '') return (format.label || "") + " ##&en Can not be blank ##&hu Nem lehet üres ##";
    }
    if (!format.validators) return true;
    let list = format.validators || [];
    if (typeof format.validators === "string") list = format.validators.split("|");
    for (const i of list) {
        const r = validators[i](data.value);
        if (r === false) return "##&en Invalid from ##&hu Érvénytelen bejegyzés ## " + i;
        if (r !== true) return r;
    }
    return true;
};

defineExpose({ refresh, isValid });

let collect = false;
async function updateHandler(d, ...a) {
    if (collect) return;
    collect = true;
    await nextTick();
    collect = false;

    let datum = clone(data.value);

    if (data.format._type === "Number" && typeof datum === "string") {
        datum = datum.replace(/,/g, ".");
        if (isNaN(Number(datum))) return validHandler();
        if (datum.endsWith(".")) return emit("update:valid", "##&en Numbers must end with a number ##&hu Számoknak számnmal kell végződniük ##");
        datum = Number(datum);
    }

    emit("update", datum, ...a);
    emit("update:modelValue", datum);
    validHandler();
}

async function clearHandler() {
    await nextTick();
    emit("update", undefined);
    emit("update:modelValue", undefined);
    return validHandler();
}

function validHandler() {
    const valid = isValid();
    emit("update:valid", valid);
}

// default value
if (props.format.default !== undefined) {
    if (data.value === undefined) {
        data.value = props.format.default;
        updateHandler();
    }
}

onMounted(async () => {
    validHandler();
    updateFunctionalvalues();
});
</script>

<script>
import * as vuetify from "vuetify/components";
import vuetiform from "../../vuetiform-components.mjs";
// allow recursive
//console.log(vuetify, vuetiform);
export default {
    inheritAttrs: false,
    components: { ...vuetify, ...vuetiform },
    name: "vuetiform-component",
};
</script>
<style scoped>
.valid {
    border-left: 3px solid rgba(0, 0, 0, 0);
}
.invalid {
    background-color: rgba(128, 0, 0, 0.02);
    border-left: 3px solid red;
}
.readonly {
    background-color: rgba(0, 0, 128, 0.06);
    border-left: 3px solid blue;
}
</style>
