<template>
    <v-container class="codebox">
        <pre class="ma-1">{{ props.pretext }}</pre>
        <vue-codemirror v-model="data.value" @change="updateHandler" :extensions="extensions" />
        <pre class="ma-1">{{ data.valid === true ? "" : "ERROR " + data.valid }}</pre>
    </v-container>
</template>

<script setup>
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
import { parse } from "acorn";

function isValidTemplateLiteralSyntax(str) {
    // This regex looks for occurrences of `${...}` in the string.
    const templateLiteralPatternFail = /\$(?!\{)|\$\{[^}]*$|(?<!\$\{[^}]*})}/;
    if (templateLiteralPatternFail.test(str)) return "##&en Invalid template literal ##&hu Hibás template literal ##";
    return true;
}

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "modelValue", "pretext"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ value: clone(props.modelValue || ""), valid: true, error: null });

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    Object.assign(data, { value: clone(props.modelValue || ""), valid: isValid() });
    await nextTick();
}

defineExpose({ refresh });

function isValid() {
    try {
        if (data.value.length > 0) parse("const template_literal = `" + data.value + "`;", { ecmaVersion: 2022, sourceType: "module", allowReturnOutsideFunction: true });
        return true;
    } catch (e) {
        return e.message;
    }
}

async function updateHandler() {
    await nextTick();
    const datum = clone(data.value);
    const valid = isValid();
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", valid);
    data.valid = valid;
}

onMounted(async () => {
    data.valid = isValid();
});

/// codemirror setup
import {
    keymap,
    highlightSpecialChars,
    drawSelection,
    highlightActiveLine,
    dropCursor,
    rectangularSelection,
    crosshairCursor,
    lineNumbers,
    highlightActiveLineGutter,
} from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { defaultHighlightStyle, syntaxHighlighting, indentOnInput, bracketMatching, foldGutter, foldKeymap } from "@codemirror/language";
import { defaultKeymap, history, historyKeymap } from "@codemirror/commands";
import { searchKeymap, highlightSelectionMatches } from "@codemirror/search";
import { autocompletion, completionKeymap, closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { lintKeymap } from "@codemirror/lint";
const basicSetup = (() => [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    history(),
    //foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    //closeBrackets(),
    autocompletion(),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    keymap.of([...closeBracketsKeymap, ...defaultKeymap, ...searchKeymap, ...historyKeymap, ...foldKeymap, ...completionKeymap, ...lintKeymap]),
])();

//-------------
const extensions = [keymap.of(defaultKeymap), basicSetup];

import { createTheme } from "thememirror";
import { cobalt } from "thememirror";
extensions.push(cobalt);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-template-literals",
};
</script>
<style>
.codebox {
}
</style>
