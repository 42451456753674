<template>
    <div class="pa-16">
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" />
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const router = useRouter();

const selection = reactive({});

const data = reactive({
    structure: {},
});

const leftClickElement = (e) => {
    router.push("/assistant/" + e.database + "/" + e._id);
};
const rightClickElement = (e) => {
    window.open("/assistant/" + e.database + "/" + e._id, "_blank");
};

onMounted(async () => {
    await $connection();

    $socket.on("assistant:Assistants:update", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("assistant:Assistants:update", {}, (o) => {
        Object.assign(data, o);
    });
});

onUnmounted(async () => {
    $socket.removeListener("assistant:Assistants:update");
});
</script>
<script>
export default {
    path: "/assistants",
    name: "##&en Assistants ##&hu Asszisztensek ##",
    icon: "mdi-chat-question",
    root: "Home",
};
</script>