<template>
    <div :class="props.classes || 'text-h7 pa-2 ml-2'">
        <v-select
            v-model="data.value"
            :label="props.label"
            :items="getItems()"
            @input="updateHandler()"
            @update:modelValue="updateHandler()"
            :prepend-icon="props.icon || 'mdi-currency-usd'"
            append-icon="mdi-arrow-down-drop-circle"
        />
    </div>
</template>
<script setup>
import { isProxy, onMounted, toRaw } from "vue";
import { ref, reactive, nextTick } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";
import { getCurrentInstance } from "vue";

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

const props = defineProps(["bond", "label", "classes", "modelValue", "items", "icon", "filters"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ value: clone(props.modelValue || null) });

function getItems() {
    const unfiltered = store.getContextkeys(props.bond.stack);
    if (!props.filters) return unfiltered;
    const filters = props.filters.split("|");
    const filtered = unfiltered.filter((e) => filters.includes(e._type));
    return filtered;
}

function isValid() {
    const arr = getItems();
    if (arr.length < 1) return "##&en No context variables ##&hu Nincsennek konteksztusváltozók ##";
    const a = arr.map((e) => e.value || e);
    if (a.includes(data.value)) return true;
    Ł(props.label, "invalid");
    return data.value + " ##&en Invalid in context ##&hu Nincs a kontextusban ##";
}

async function updateHandler() {
    await nextTick();
    const datum = clone(data.value);
    emit("update", datum);
    emit("update:modelValue", datum);
    validHandler();
}

function validHandler() {
    emit("update:valid", isValid());
}

async function refresh() {
    Object.assign(data, { value: clone(props.modelValue || "") });
    await nextTick();
    validHandler();
}

defineExpose({ refresh });

onMounted(updateHandler);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "eventhandler-contextkey-select",
};
</script>
