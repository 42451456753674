<template>
    <div class="pa-16">
        <vuetifind-dialog :format="data.format" v-model="data.values" @clickEnter="enter" label="##&en Add new ##&hu Hozzáadása ##" />
        <selector-component :structure="data.structure" @leftClickElement="click" />
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const emit = defineEmits(["leftClickElement"]);

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({
    structure: {},
    format: {},
    values: {},
    baseuri: "/base",
});

const click = (element) => {
    Ł("click:", element);
    //router.push(data.baseuri + "/" + top + "/" + mid + "/" + sub);
};
const enter = (element) => {
    Ł("enter:", element);
    //router.push(data.baseuri + "/" + top + "/" + mid + "/" + sub);
};
onMounted(async () => {
    await $connection();

    //$socket.emit("update-database");

    Object.assign(data, {
        structure: {
            one: {
                alpha: {
                    alphaOne: [
                        { name: "AlphaOne A-1 D1", icon: "mdi-database" },
                        { name: "AlphaOne A-1 D2", icon: "mdi-database" },
                    ],
                    alphaTwo: [
                        { name: "AlphaTwo A-2 D1", icon: "mdi-database", icon: "mdi-database" },
                        { name: "AlphaTwo A-2 D2", icon: "mdi-database" },
                        { name: "AlphaTwo A-3 D1", icon: "mdi-database" },
                        { name: "AlphaTwo A-4 D2", icon: "mdi-database" },
                    ],
                },
                bravo: {
                    bravoOne: [
                        { name: "Bravo B-1 D1", icon: "mdi-database" },
                        { name: "Bravo B-1 D2", icon: "mdi-database" },
                    ],
                    bravoTwo: [
                        { name: "Charlie B-1 D1", icon: "mdi-database" },
                        { name: "Charlie B-1 D2", icon: "mdi-database" },
                    ],
                },
                charlie: [
                    { name: "Charlie B-1 D1", icon: "mdi-database" },
                    { name: "Charlie B-1 D2", icon: "mdi-database" },
                ],
            },
            two: {
                tumik: {
                    polo: {
                        cuis: [{ name: "Cuis", icon: "mdi-database" }],
                    },
                },
            },
        },
        format: {
            email: {
                is: "v-text-field",
                label: "E-mail",
                validators: "validateEmail",
            },
            password: {
                is: "v-text-field",
                label: "Email Password",
                "append-icon": "mdi-eye",
                //disabled: true,
            },
        },
    });

    //$socket.on("update-database", (o) => {
    //    Object.assign(data, o);
    //});
});
</script>
<script>
export default {
    path: "/hello-triple-selection",
    name: "triple-selection",
    icon: "mdi-database",
    sort: 100,
    root: "Home",
};
</script>
