// VuetiformComponent.vue 46

export default function functionalValues({ format, value }) {
    for (const key in format) {
        if (key.includes(":") && typeof format[key] === "function") {
            const target = key.split(":").pop();
            const type = key.split(":")[0];
            //const result = format[key].getFunctionWithContext(this)(value);
            const result = format[key].bind(this)(value);
            //Ł(key, this, value, result);

            if (Object.prototype.toString.call(result) === "[object " + type + "]") format[target] = result;
            else console.error("%cError in return type " + Object.prototype.toString.call(result) + ", expected " + type, "color: red;");
        }
    }
}
