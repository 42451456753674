<template>
    <div v-for="(definition, key, i) in data.format" :key="masterkey + '-' + key">
        <vuetiform-component
            :key="masterkey + key + i"
            :format="definition"
            v-model="data.values[key]"
            v-model:valid="valid[key]"
            @update="
                updateHandler(key);
                updateCalc(key);
            "
            :disabled="definition.disabled"
        />
    </div>
</template>

<script setup>
// DEPRECATED for serverform
// dynamically load data from socket.io
// update the format at each change of data

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { compare } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["format", "modelValue", "disabled", "interrelation"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ format: clone(props.format), values: clone(props.modelValue || {}) });
const masterkey = ref(0);

// TODO refresh

const baseFormat = clone(props.format || {});
const keymap = Object.keys(baseFormat);

let updateRequired = false;
const updateCalc = (from) => {
    updateRequired = true;
};

let lastChecksum = "";
const updateEmitter = () => {
    if (!updateRequired) return;
    updateRequired = false;

    const values = clone(data.values);
    const format = clone(data.format);

    $socket.emit(props.interrelation, { values, key: masterkey.value }, (reply) => {
        // detect changes, so we can increase the masterkey and update the view if changed.
        // if the data argument is provided, it is possible to clear data
        if (reply.data) Object.assign(data, reply.data);

        // additional update on values
        if (reply.values) {
            Object.assign(data.values, reply.values);
            if (compare(values, clone(data.values)) === false) {
                masterkey.value++;
                updateHandler();
            }
        }
        // additional update on format
        if (reply.format) {
            Object.assign(data.format, reply.format);
            if (compare(format, clone(data.format)) === false) masterkey.value++;
        }
    });
};

// validators
const valid = reactive({});
const isValid = () => {
    for (const i in valid) if (valid[i] !== true) return i + ": " + valid[i];
    return true;
};

const updateHandler = async (from) => {
    await nextTick();
    const datum = clone(data.values);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};
//updateHandler();
emit("update:valid", isValid());

onMounted(async () => {
    emit("update:valid", isValid());
    updateHandler("onMounted");
    updateCalc("onMounted");
    setInterval(updateEmitter, 100);
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-dynaform",
};
</script>
