<template>
    <v-file-input :label="props.label || 'File'" v-model="data.files" @update:modelValue="updateHandler" :accept="props.accept" :disabled="props.disabled"></v-file-input>
    <file-display v-if="props.display" :bond="bond" :subpath="subpath" :filename="data.filename" :key="data.filename" />
</template>
<script setup>
import { ref, reactive, watch, nextTick, toRaw, computed } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
import FileDisplay from "@/vuetiform-components/FileDisplay.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const emit = defineEmits(["update:modelValue", "update:valid", "update", "input", "accept"]);
const props = defineProps(["bond", "module", "channel", "modelValue", "subpath", "label", "is", "class", "disabled", "display"]);

let files = [];
if (props.modelValue) files = [new File([], props.modelValue)];
const data = reactive({ file: props.modelValue, files, filename: props.modelValue });

const isValid = () => {
    if (props.mandatory === true) if (data.files.length < 1) return (props.label || "") + " ##&en file is required ##&hu file megadása kötelező kötelező ##";
    return true;
};

const updateHandler = async () => {
    await nextTick();

    const valid = isValid();
    let datum = null;
    // TODO ez mintha nem menne
    emit("update:valid", false);
    if (valid !== true) return;
    if (!data.files) return;

    const file = data.files[0];
    if (!file) return;

    const eventName = (props.module || "vuetifiles") + ":" + (props.channel || "upload");

    Ł("VuetiformFile upload", eventName);

    $socket.emit(
        eventName,
        file,
        {
            name: file.name,
            type: file.type,
            subpath: props.subpath,
            route: route.path,
            bond: props.bond,
        },
        ({ filename }) => {
            if (!filename) return emit("update:valid", false);
            data.files = [new File([], filename)];
            datum = filename;
            emit("update", datum);
            emit("update:modelValue", datum);
            emit("update:valid", valid);
            //console.log("VuetiformFile callback");
            data.filename = filename;
        }
    );
};
const updateMenu = (e) => {
    if (!selecting) data.index = null;
};
</script>
<script>
export default {
    inheritAttrs: false,
};
</script>
