<template>
    <v-card class="mx-auto pa-3 ma-3" v-bind="data.card">
        <v-card-actions v-if="props.closeable">
            <v-spacer />
            <v-btn variant="plain" icon="mdi-close" @click="emit('close')"></v-btn>
        </v-card-actions>
        <vuetiform-poliform :format="data.format" v-model="data.document" v-model:valid="data.valid" :key="data.key" :disabled="data.readonly || data.document._readonly" />
        <v-card-actions>
            <component
                v-for="(v, k, i) in data.actions"
                :is="v.format.is"
                :bond="props.bond"
                v-bind="v.format"
                class="pa-3"
                @click="click(v, k)"
                color="primary"
                variant="elevated"
                :disabled="isDisabled(v)"
            />
        </v-card-actions>
    </v-card>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, toRaw } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["module", "channel", "bond", "closeable", "card", "actions", "document", "format"]);
const emit = defineEmits(["close"]);

const data = reactive({
    card: clone(props.card || {}),
    document: clone(props.document || {}),
    format: clone(props.format || {}),
    actions: clone(props.actions || {}),
    valid: true,
    key: 0,
});

function isDisabled(v) {
    if (v.format.disabled === true) return true;
    if (v.format.disabled === "@invalid-form") return data.valid !== true;
    if (v.format.disabled === "@valid-form") return data.valid === true;
    return false;
}

function click(v, action) {
    if (!v["@click:socket.emit"]) return;
    $socket.emit(v["@click:socket.emit"], { action, document: data.document }, (o) => {
        Object.assign(data, o);
        data.key++;
        Ł(o);
        //if (props.closable && data.actions[key].close) emit("close");
    });
}

/*const init = (a) => {
    $socket.emit(props.module + ":" + props.channel + ".init", (o) => {
        Object.assign(data, o);
    });
};*/

onMounted(async () => {
    await $connection();
    //init();
});
</script>
<script>
import * as vuetify from "vuetify/components";
import vuetiform from "../../vuetiform-components.mjs";
// allow recursive

export default {
    inheritAttrs: false,
    components: { ...vuetify, ...vuetiform },
    name: "vuetiform-card",
};
</script>
