// vuetify theme
export default {
    dark: true,
    colors: {
        background: "#00254b",
        surface: "#001245",
        primary: "#00458b", // Cobalt blue
        //"primary-darken-1": "#003171", // Darkened cobalt blue
        secondary: "#00356b", // Lighter, secondary cobalt blue
        //"secondary-darken-1": "#3399FF", // Slightly darkened secondary cobalt blue
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
};
