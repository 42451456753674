<template>
    <v-text-field class="pa-3" prepend-icon="mdi-scale" v-bind="reprops()" />
</template>
<script setup>
// under construction

const props = defineProps(["modelValue", "disabled", "label", "hint", "unit", "default", "clearable", "mandatory", "readonly"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

import { ref, reactive, watch, nextTick, toRaw } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

const reprops = () => {
    const re = clone(props);
    delete re.items;
    delete re.modelValue;
    re.multiple = true;
    re.chips = true;
    return re;
};
</script>
