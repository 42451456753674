export default function compare(i, j) {
    const type = Object.prototype.toString.call(i);

    if (type !== Object.prototype.toString.call(j)) return false;

    if (type === "[object Date]") return i.getTime() === j.getTime();

    if (type === "[object Array]") {
        return i.length === j.length && i.every((elem, index) => compare(elem, j[index]));
    }

    if (type === "[object Object]") {
        const keysI = Object.keys(i);
        const keysJ = Object.keys(j);

        return keysI.length === keysJ.length && keysI.every((key) => Object.prototype.hasOwnProperty.call(j, key) && compare(i[key], j[key]));
    }

    return i === j;
}

/*
export default function compare(i, j) {
    if (Object.prototype.toString.call(i) !== Object.prototype.toString.call(j)) return false;

    if (Object.prototype.toString.call(i) === "[object Date]") return i.getTime() === j.getTime();

    if (Object.prototype.toString.call(i) === "[object Array]") {
        if (i.length !== j.length) return false;
        const arr = i.map((e, x) => compare(i[x], j[x]));
        if (arr.includes(false)) return false;
        return true;
    }

    if (Object.prototype.toString.call(i) === "[object Object]") {
        if (Object.keys(i).length !== Object.keys(j).length) return false;
        const copy = {};
        for (const attr in i) if (Object.prototype.hasOwnProperty.call(i, attr)) copy[attr] = compare(i[attr], j[attr]);
        if (Object.values(copy).includes(false)) return false;
        return true;
    }

    //if (Object.prototype.toString.call(i) === "[object Function]") return i;
    //if (Object.prototype.toString.call(i) === "[object AsyncFunction]") return i;

    return i === j;
}
*/
